<template>
  <span class="agge-groups-modal" v-if="hasPerm('youth.change_agegroup')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      :ok-disabled="formInvalid || isLoading(loadingName)"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-people-group"></i> Modifier les groupes d'âge</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="seanceDate">
        <b-row>
          <b-col cols="8">
            <b-form-group description="les groupes d'âge s'appliqueront seulement ici">
              <b-form-select v-model="selectedSeance" id="selectedSeance">
                <b-form-select-option :value="null" v-if="!onlyExcursions">
                  Pour la journée du {{ seanceDate | dateToString }}
                </b-form-select-option>
                <b-form-select-option v-for="elt of seances" :value="elt" :key=elt.id>
                  <span v-if="!elt.isExcursion()">
                    Pour la séance {{ elt.name }}
                  </span>
                  <span v-else>
                    {{ elt.name }}
                  </span>
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group description="copier ces groupes jusqu'au" v-if="selectedSeance === null">
              <b-form-input
                type="date"
                :min="minDate"
                :max="maxDate"
                id="applyTo"
                v-model="applyTo"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="(period || seanceDate)" class="info-text">
        <b-row>
          <b-col cols="12">
            <div v-if="seanceDate">
              <div v-if="selectedSeance && selectedSeance.excursion">
                <i class="fa fa-calendar-day"></i>
                Ces groupes d'âge s'appliquent seulement pour {{ selectedSeance.name }}
              </div>
              <div v-else-if="selectedSeance">
                <i class="fa fa-calendar-day"></i>
                Ces groupes d'âge s'appliquent seulement pour la séance {{ selectedSeance.name }}
              </div>
              <div v-else-if="applyTo">
                <i class="fa fa-calendar-days"></i>
                Ces groupes d'âge s'appliquent pour les dates du {{ seanceDate | dateToString }} au
                {{ applyTo | dateToString }}
              </div>
              <div v-else>
                <i class="fa fa-calendar-day"></i>
                Ces groupes d'âge s'appliquent seulement pour la date du {{ seanceDate | dateToString }}
              </div>
            </div>
            <div v-else-if="period" class="info-text">
              <i class="fa fa-calendar-days"></i>
              Ces groupes d'âge s'appliquent seulement pour la période {{ period.name }}
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="gr-line" v-if="isCustom">
        <b-row>
          <b-col class="text-left">
            <b-form-checkbox
              id="deleteCustom" v-model="deleteCustom"
            >
              Supprimer les groupes d'âge {{ customName }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
      <div v-if="!deleteCustom">
        <div class="text-right gr-line">
          <b-row>
            <b-col class="text-center" cols="3">
              <b v-if="ageGroups.length">Âge de début</b>
            </b-col>
            <b-col class="text-center"></b-col>
            <b-col>
              <a class="btn btn-secondary btn-sm" @click.prevent="addAgeGroup">
                <i class="fa fa-plus"></i> Ajouter
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="gr-line" v-for="group of ageGroups" :key="group.id">
          <b-row >
            <b-col class="text-center" cols="3">
              <b-form-input type="number" :id="'ageGr' + group.id" v-model="group.startAge" min="0" step="1">
              </b-form-input>
            </b-col>
            <b-col class="text-center">
              {{ groupName(group) }}
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-danger btn-sm" @click.prevent="removeAgeGroup(group)">
                <i class="fa fa-trash"></i> Supprimer
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="gr-line" v-if="period">
          <b-row>
            <b-col class="text-right">
              <a class="btn btn-secondary btn-sm" @click.prevent="loadDefaults()">
                <i class="fa fa-copy"></i> Charger les groupes par défaut
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="gr-line" v-if="seanceDate">
          <b-row>
            <b-col class="text-right">
              <a class="btn btn-secondary btn-sm" @click.prevent="loadPeriod()">
                <i class="fa fa-copy"></i> Charger les groupes de la période
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="period === null" class="section-block">
        <b-row>
          <b-col>
            Modifier les inscriptions pour les séances à partir du
          </b-col>
          <b-col class="text-center" cols="4">
            <b-form-input type="date" v-model="updateSince" id="updateSince">
            </b-form-input>
          </b-col>
        </b-row>
      </div>
      <div class="info-text">
        <i class="fa fa-warning"></i> Attention! Pensez à modifier les limites pour correspondre aux nouveaux groupes.
      </div>
      <loading-gif :loading-name="loadingName" message="Veuillez patienter"></loading-gif>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { min, max } from '@/utils/math'
import { makeAgeGroup } from '@/types/youth'
import { dateToString } from '@/filters/texts'
import { compareDays } from '@/utils/sorting'

export default {
  name: 'AgeGroupsModal',
  mixins: [BackendMixin],
  components: {
    LoadingGif,
  },
  props: {
    modalId: String,
    youthHome: Object,
    seanceType: Object,
    period: {
      type: Object,
      default: null,
    },
    seanceDate: {
      type: String,
      default: '',
    },
    seances: {
      type: Array,
      default: () => { return [] },
    },
    ageGroupsMgr: Object,
  },
  data() {
    return {
      loadingName: 'save-age-groups',
      ageGroups: [],
      errorText: '',
      seanceOption: 0,
      deleteCustom: false,
      applyTo: null,
      minDate: null,
      maxDate: null,
      updateSince: null,
      selectedSeance: null,
    }
  },
  computed: {
    formInvalid() {
      let lastAge = 0
      if (!(this.isCustom && this.deleteCustom)) {
        if (this.ageGroups.length === 0) {
          return true
        }
      }
      for (const ageGroup of this.ageGroups) {
        let age = +ageGroup.startAge
        if (age <= lastAge) {
          return true
        }
        lastAge = age
      }
      if (this.seanceDate && (this.selectedSeance === null) && this.applyTo) {
        if (compareDays(this.minDate, this.applyTo) >= 0) {
          return true
        }
        if (compareDays(this.applyTo, this.maxDate) >= 0) {
          return true
        }
      }
      return false
    },
    onlyExcursions() {
      if (this.seances.length > 0) {
        for (const seance of this.seances) {
          if (!seance.isExcursion()) {
            return false
          }
        }
        return true
      }
      return false
    },
    isCustom() {
      if (this.seanceDate) {
        if (this.selectedSeance && this.selectedSeance.isExcursion()) {
          return this.ageGroupsMgr.hasSeanceAgeGroups(this.selectedSeance)
        } else {
          if (this.selectedSeance) {
            return this.ageGroupsMgr.hasSeanceAgeGroups(this.selectedSeance)
          } else {
            return this.ageGroupsMgr.hasDateAgeGroups(this.seanceDate)
          }
        }
      } else if (this.period) {
        return this.ageGroupsMgr.hasPeriodAgeGroups(this.period)
      }
      return false
    },
    customName() {
      if (this.seanceDate) {
        if (this.selectedSeance && this.selectedSeance.isExcursion()) {
          return 'pour ' + this.selectedSeance.name
        } else {
          if (this.selectedSeance) {
            return 'pour la séance ' + this.selectedSeance.name
          } else {
            return 'pour la journée du ' + dateToString(this.seanceDate)
          }
        }
      } else if (this.period) {
        return 'pour la période ' + this.period.name
      }
      return ''
    },
  },
  watch: {
    seanceDate() {
      this.init()
    },
    period() {
      this.init()
    },
    ageGroupsMgr() {
      this.init()
    },
    selectedSeance() {
      if (this.selectedSeance) {
        if (this.selectedSeance.isExcursion()) {
          this.ageGroups = this.ageGroupsMgr.getSeanceAgeGroups(this.selectedSeance)
        } else {
          const hasSeanceGroups = this.ageGroupsMgr.hasSeanceAgeGroups(this.selectedSeance)
          if (hasSeanceGroups) {
            this.ageGroups = this.ageGroupsMgr.getSeanceAgeGroups(this.selectedSeance)
          } else {
            this.ageGroups = []
          }
        }
      } else {
        this.ageGroups = this.ageGroupsMgr.getDateAgeGroups(this.seanceDate)
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      this.deleteCustom = false
      this.ageGroups = []
      this.applyTo = null
      this.updateSince = moment().format('YYYY-MM-DD')
      this.selectedSeance = null
      if (this.onlyExcursions) {
        this.selectedSeance = this.seances[0]
      } else if (this.seanceDate) {
        if ((this.seances.length === 1)) {
          const seance = this.seances[0]
          const hasSeanceGroups = this.ageGroupsMgr.hasSeanceAgeGroups(seance)
          if (hasSeanceGroups) {
            this.selectedSeance = seance
          }
        }
        this.ageGroups = this.ageGroupsMgr.getDateAgeGroups(this.seanceDate)
        this.minDate = moment(this.seanceDate).add(1, 'day').format('YYYY-MM-DD')
        this.maxDate = moment(this.seanceDate).add(30, 'day').format('YYYY-MM-DD')
      } else if (this.period) {
        this.ageGroups = this.ageGroupsMgr.getPeriodAgeGroups(this.period)
      } else {
        this.ageGroups = this.ageGroupsMgr.getGlobalAgeGroups()
      }
    },
    async onSave() {
      this.startLoading(this.loadingName)
      this.errorText = ''
      if (this.youthHome && this.seanceType) {
        let url = '/api/youth/save-age-groups/' + this.youthHome.id + '/' + this.seanceType.id + '/'
        let backendApi = new BackendApi('post', url)
        let ageGroups = []
        if (!(this.isCustom && this.deleteCustom)) {
          ageGroups = this.ageGroups.map(
            elt => {
              return {
                id: elt.id,
                start_age: +elt.startAge,
              }
            }
          )
        }
        const data = {
          age_groups: ageGroups,
          period: this.period ? this.period.id : null,
          date: (this.seanceDate && this.selectedSeance === null) ? this.seanceDate : null,
          seance: (this.selectedSeance !== null) ? this.selectedSeance.id : null,
          delete_custom: this.isCustom ? this.deleteCustom : false,
          update_since: this.period ? null : this.updateSince, // seulement pour les groupes globaux
        }
        if (this.seanceDate && (this.selectedSeance === null) && this.applyTo) {
          let curDate = this.seanceDate
          let max = 30
          let counter = 0
          let noErr = true
          let successMessage = 'Les groupes d\'âge ont été modifiés pour les dates sélectionnées'
          while ((compareDays(curDate, this.applyTo) <= 0) && (counter < max)) {
            data.date = curDate
            try {
              await backendApi.callApi(data)
            } catch (err) {
              this.errorText = this.getErrorText(err)
              noErr = false
              break
            }
            counter += 1
            curDate = moment(curDate).add(1, 'day').format('YYYY-MM-DD')
          }
          if (noErr) {
            this.$bvModal.hide(this.modalId)
            this.$emit('refresh')
            await this.addSuccess(successMessage)
          }
        } else {
          let successMessage = 'Les groupes d\'âge ont été modifiés'
          try {
            await backendApi.callApi(data)
            this.$bvModal.hide(this.modalId)
            this.$emit('refresh')
            await this.addSuccess(successMessage)
          } catch (err) {
            this.errorText = this.getErrorText(err)
          }
        }
      }
      this.endLoading(this.loadingName)
    },
    addAgeGroup() {
      let newId = -1
      const newIds = this.ageGroups.filter(elt => elt.id < 0).map(elt => elt.id)
      if (newIds.length > 0) {
        newId = min(newIds) - 1
      }
      let startAge = 1
      if (this.ageGroups.length > 0) {
        startAge = max(this.ageGroups.map(elt => elt.startAge)) + 1
      }
      this.ageGroups.push(makeAgeGroup({ id: newId, start_age: startAge, }))
    },
    removeAgeGroup(group) {
      const index = this.ageGroups.map(elt => elt.id).indexOf(group.id)
      if (index >= 0) {
        this.ageGroups.splice(index, 1)
      }
    },
    groupName(group) {
      const index = this.ageGroups.map(elt => elt.id).indexOf(group.id)
      if (index < 0) {
        return ''
      } else if (index < (this.ageGroups.length - 1)) {
        const startAge = +this.ageGroups[index].startAge
        const nextStartAge = +this.ageGroups[index + 1].startAge
        const endAge = (nextStartAge - 1)
        if (nextStartAge > startAge) {
          if (+endAge === +startAge) {
            return (startAge === 1) ? '1 an' : ('' + startAge + ' ans')
          } else {
            return '' + startAge + '-' + endAge + ' ans'
          }
        } else {
          return 'invalide'
        }
      } else {
        const startAge = this.ageGroups[index].startAge
        if (startAge > 1) {
          return '' + startAge + ' ans et +'
        } else {
          return '' + startAge + ' an et +'
        }
      }
    },
    loadDefaults() {
      let ageGroups = this.ageGroupsMgr.getGlobalAgeGroups()
      this.ageGroups = ageGroups.map(
        (elt, index) => {
          return makeAgeGroup(
            {
              id: -(index + 1),
              start_age: +elt.startAge,
            }
          )
        }
      )
    },
    loadPeriod() {
      let ageGroups = []
      if (this.ageGroupsMgr.hasPeriodAgeGroups(this.period)) {
        ageGroups = this.ageGroupsMgr.getPeriodAgeGroups(this.period)
      } else {
        ageGroups = this.ageGroupsMgr.getGlobalAgeGroups()
      }
      this.ageGroups = ageGroups.map(
        (elt, index) => {
          return makeAgeGroup(
            {
              id: -(index + 1),
              start_age: +elt.startAge,
            }
          )
        }
      )
    },
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #ad4a25;
    background: #e0e0e0;
  }
  .gr-line {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: #ccc 1px solid;
  }
  .gr-line:last-child {
    border-bottom: none;
  }
  .gr-warning {
    background: #f1eeb4;
  }
  .section-block {
    padding: 5px;
    margin: 5px 0;
    border: solid 1px #eee;
  }
</style>
